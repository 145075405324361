import { useRef } from "react";
import NavUl from "./NavUl.js";
import './Nav.css';
// const dropDownNav = useRef({});
function dropDown(e){
  // debugger
  document.querySelector(".")
  const toggle = false;

}
function Navbar() {
  return (
    <div class="container w-50">
 <nav class=" p-3 navbar navbar-expand-lg ">
  <a class="navbar-brand" href="#">Soulsfix</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="w-100 p-3 collapse navbar-collapse" id="navbarSupportedContent">
    <NavUl/>
    <form class="d-flex form-outline my-2 my-lg-1 a-left p-3 ">
      <input class="form-control mr-sm-2 mx-2" type="search" placeholder="Pesquisar" aria-label="Search"/>
      <button class="btn btn-outline-dark my-2 my-sm-0" type="submit"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
</svg></button>
    </form>
        <button class="btn btn-primary">
          <a class="text-white text-decoration-none" href="#Contato">Entrar em Contato
            </a> 
          </button>
  </div>
</nav>
    </div>
     
  );
}

export default Navbar;
