import logo from './logo.svg';
import './App.css';
import Navbar from './Nav/Nav.js';
import Card from './Components/Card.js';
import Form from './Components/Form.js';
import Footer from './Components/Footer.js';
import Infos from './Components/Infos.js';
import logoD from './images/d365.jpg';
import logoW from './images/website.png';
import bgTop from './images/bg.jpg';


function App() {
  return (
    // <div className="App" style={{backgroundColor:"rgba(60, 73, 87, 1)"}}>
    <div className="App">
      <Navbar />
      <header className="App-title container" style={{ minHeight: '80vh' }}><br />
        <div class="d-inline-flex container">
          <div class="text-start">
            <br />
            <br />
            <br />
            <br />
            <h3 class="">Inicie seu processo com nossos serviços</h3>
            <br />
            <h5 class="text-left">Tem interesse em projetar uma solução para seu projeto, entre em contato com a nossa equipe de especialistas
            </h5>
            <br />
            <a href="#Contato" class="mx-1 btn btn-dark">Entrar em contato</a>
          </div>

          <img src={bgTop} class="text-end opacity-75 " style={{ width: '60%' }} alt="..." />
        </div>

        <div class="mx-100">

          <h2 class="p-3">Encontre os melhores serviçoes para sua empresa</h2>
          <br />
          <div className="row w-100 left-25 container">
            <div class="col-md-3" style={{ width: 'fit-content', marginBottom: '3em', fontSize: '18px' }} >
              <Card
                ImageCard='{}'
                title='Dynamics'
                ServiceResume='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ultrices velit quam, eu vehicula ante dictum ut. Maecenas non elit nec velit commodo mollis.'
                item1='Sobre'
                item2='Possibilidades'
                item3='Exemplos'
              />
            </div>
            <div class="col-sm-3" style={{ width: 'fit-content', marginBottom: '3em', fontSize: '18px' }} >
              <Card
                ImageCard='{}'
                title='Website e Marketing'
                ServiceResume='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ultrices velit quam, eu vehicula ante dictum ut. Maecenas non elit nec velit commodo mollis.'
                item1='Sobre'
                item2='Possibilidades'
                item3='Exemplos'
              />
            </div>
            <div class="col-sm-3" style={{ width: 'fit-content', marginBottom: '3em', fontSize: '18px' }} >
              <Card
                title='Integrações'
                ServiceResume='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ultrices velit quam, eu vehicula ante dictum ut. Maecenas non elit nec velit commodo mollis.'
                item1='Sobre'
                item2='Possibilidades'
                item3='Exemplos'
              />
            </div>
            <div class="col-sm-3" style={{ width: 'fit-content', marginBottom: '3em', fontSize: '18px' }} >
              <Card
                title='Testes integrados'
                ServiceResume='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ultrices velit quam, eu vehicula ante dictum ut. Maecenas non elit nec velit commodo mollis.'
                item1='Sobre'
                item2='Possibilidades'
                item3='Exemplos'
              />
            </div>

          </div>
        </div>

      </header>
      <body >
        <br/>
        <br/>
        <h3>Entre em contato com nossa equipe e tire todas suas dúvidas</h3>
        <br/>
        <br/>
        <div className='container'>

        <div id="Contato" class="row">
        <div class="col-xl-5 ">
        <Infos
        img={logoW}
        />
        <br/>
        <br/>
        </div>
        <div class="col-xl-7 ">

        <Form/>
        </div>
        </div>
        </div>
      </body>
      <Footer/>
    </div>
  );
}

export default App;
