

function Form(props) {
  return (
    <form>
    <div class="container">
      <div class=" input-group mb-3">
        <input type="text" class="form-control" placeholder="Nome" aria-label="Username" aria-describedby="basic-addon1" />
      </div>

      <div class="input-group mb-3">
        <input type="text" class="form-control" placeholder="Email" aria-label="Recipient's username" aria-describedby="basic-addon2" />
      </div>

      <div class="input-group mb-3">
      <span class="input-group-text">(+55)</span>
        <input type="text" class="form-control" placeholder="Telefone" aria-label="Recipient's username" aria-describedby="basic-addon2" />
      </div>

      <div class="input-group">
        <textarea class="form-control" placeholder='Escreva sua mensagem' aria-label="With textarea"></textarea>
      </div>
    </div>
    <div class="mb-3 form-check">
  </div>
    <button type="submit" class="btn btn-primary">Enviar Mensagem</button>
      </form>
     
  );
}

export default Form;